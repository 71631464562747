import React, { Component } from 'react'
import { Link } from 'gatsby'
import { graphql } from 'gatsby'
import { Scroll } from 'inno-components'

import SecondaryLayout from '../components/layouts/SecondaryLayout'

import Accueil from '../components/home/Accueil'
import VosCampagnes from '../components/home/VosCampagnes'
import Contact from '../components/home/Contact'
import NotreEntreprise from '../components/home/NotreEntreprise'
import CampagneDeNotoriete from '../components/home/CampagneDeNotoriete'

import SetLanguageFromPage from '../config/i18n/SetLanguageFromPage'

class IndexPage extends Component {
  componentDidMount() {
    Scroll.scrollSpy.update()

    let hash = window.location.hash
    hash = hash.replace('#', '')

    if (hash && hash != '') {
      console.log('hash : ' + hash)
      Scroll.scroller.scrollTo(hash)
    }
  }
  render() {
    return <CampagneDeNotoriete />
  }
}

export default SetLanguageFromPage()(IndexPage)
