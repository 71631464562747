import React, { Component } from 'react'
import { StyleSheet, css } from 'aphrodite'
import { withNamespaces } from 'react-i18next'
import { StaticQuery, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Img from 'gatsby-image'
import { Section, SliderBasic, Button } from 'inno-components'
import { Container, Row, Col } from 'react-grid-system'
import Lightbox from 'react-image-lightbox'

import SecondaryLayout from '../layouts/SecondaryLayout'
import Classes from '../../styles/classes'

const styles = StyleSheet.create({
  menu: {
    alignItems: 'center',
    backgroundColor: '#333',
    borderRadius: 0,
    padding: '3px',
    zIndex: 9,
  },
  imageContainer: {
    padding: '1em',
  },
  button: {
    display: 'block',
    width: '100%',
    height: 'auto',
    padding: 0,
  },
})

const responsive = [
  { breakpoint: 768, settings: { slidesToShow: 2 } },
  { breakpoint: 1024, settings: { slidesToShow: 5 } },
]

class CampagneDeNotoriete extends Component {
  constructor(props) {
    super(props)

    this.state = {
      photoIndex: 0,
      isOpen: false,
    }
  }

  render() {
    const { photoIndex, isOpen } = this.state
    const { t } = this.props
    return (
      <StaticQuery
        query={graphql`
          query CampagneDeNotorieteImageQuery {
            notoriete1: file(relativePath: { eq: "home/notoriete1.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 800, maxHeight: 600) {
                  ...GatsbyImageSharpFluid_withWebp
                }
                fixed(width: 1200, height: 840) {
                  ...GatsbyImageSharpFixed_withWebp
                  src
                }
              }
            }
            notoriete2: file(relativePath: { eq: "home/notoriete2.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 800, maxHeight: 600) {
                  ...GatsbyImageSharpFluid_withWebp
                }
                fixed(width: 1200, height: 840) {
                  ...GatsbyImageSharpFixed_withWebp
                  src
                }
              }
            }
            notoriete3: file(relativePath: { eq: "home/notoriete3.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 800, maxHeight: 600) {
                  ...GatsbyImageSharpFluid_withWebp
                }
                fixed(width: 1200, height: 840) {
                  ...GatsbyImageSharpFixed_withWebp
                  src
                }
              }
            }
            notoriete4: file(relativePath: { eq: "home/notoriete4.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 800, maxHeight: 600) {
                  ...GatsbyImageSharpFluid_withWebp
                }
                fixed(width: 1200, height: 840) {
                  ...GatsbyImageSharpFixed_withWebp
                  src
                }
              }
            }
            notoriete5: file(relativePath: { eq: "home/notoriete5.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 800, maxHeight: 600) {
                  ...GatsbyImageSharpFluid_withWebp
                }
                fixed(width: 1200, height: 840) {
                  ...GatsbyImageSharpFixed_withWebp
                  src
                }
              }
            }
            notoriete6: file(relativePath: { eq: "home/notoriete6.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 800, maxHeight: 600) {
                  ...GatsbyImageSharpFluid_withWebp
                }
                fixed(width: 1200, height: 840) {
                  ...GatsbyImageSharpFixed_withWebp
                  src
                }
              }
            }
            notoriete7: file(relativePath: { eq: "home/notoriete7.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 800, maxHeight: 600) {
                  ...GatsbyImageSharpFluid_withWebp
                }
                fixed(width: 1200, height: 840) {
                  ...GatsbyImageSharpFixed_withWebp
                  src
                }
              }
            }
          }
        `}
        render={data => {
          const imgs = [
            data.notoriete1.childImageSharp,
            data.notoriete2.childImageSharp,
            data.notoriete3.childImageSharp,
            data.notoriete4.childImageSharp,
            data.notoriete5.childImageSharp,
            data.notoriete6.childImageSharp,
            data.notoriete7.childImageSharp,
          ]

          const ImgButtons = imgs.map((img, i) => (
            <div className={css(styles.imageContainer)} key={img.fixed.src}>
              <Button
                className={css(styles.button)}
                onClick={() => this.setState({ photoIndex: i, isOpen: true })}
              >
                <Img fluid={img.fluid} />
              </Button>
            </div>
          ))
          return (
            <SecondaryLayout subtitle={t('campagne_notoriete.titre')}>
              <Helmet
                title={t('campagne_notoriete.head.title')}
                meta={[
                  {
                    name: 'description',
                    content: t('campagne_notoriete.head.description'),
                  },
                ]}
              />
              <Section className={css(Classes.section)}>
                {typeof window !== 'undefined' && isOpen && (
                  <Lightbox
                    mainSrc={imgs[photoIndex].fixed.src}
                    nextSrc={imgs[(photoIndex + 1) % imgs.length].fixed.src}
                    prevSrc={
                      imgs[(photoIndex + imgs.length - 1) % imgs.length].fixed
                        .src
                    }
                    onCloseRequest={() => this.setState({ isOpen: false })}
                    onMovePrevRequest={() =>
                      this.setState({
                        photoIndex:
                          (photoIndex + imgs.length - 1) % imgs.length,
                      })
                    }
                    onMoveNextRequest={() =>
                      this.setState({
                        photoIndex: (photoIndex + 1) % imgs.length,
                      })
                    }
                  />
                )}
                <Container>
                  <Row>
                    <Col>
                      <p>{t('campagne_notoriete.accroche')}</p>
                    </Col>
                  </Row>
                </Container>
              </Section>
              <Section className={css(Classes.section)}>
                <Container>
                  <Row>
                    <Col>
                      <h2>{t('campagne_notoriete.sections.0.titre')}</h2>
                      <ul>
                        <li>{t('campagne_notoriete.sections.0.liste.0')}</li>
                        <li>{t('campagne_notoriete.sections.0.liste.1')}</li>
                        <li>{t('campagne_notoriete.sections.0.liste.2')}</li>
                      </ul>
                    </Col>
                    <Col xs={12} md={6}>
                      <iframe
                        src="https://www.google.com/maps/d/embed?mid=1hwyw4sciV9o0hscE-4JR7nA5I0k"
                        width="100%"
                        height="100%"
                      />
                    </Col>
                  </Row>
                </Container>
              </Section>
              <Section
                className={css(Classes.section)}
                style={{ backgroundColor: '#fff' }}
              >
                <Container>
                  <Row>
                    <Col>
                      <h2>{t('campagne_notoriete.sections.1.titre')}</h2>
                      <ul>
                        <li>{t('campagne_notoriete.sections.1.liste.0')}</li>
                        <li>{t('campagne_notoriete.sections.1.liste.1')}</li>
                        <li>{t('campagne_notoriete.sections.1.liste.2')}</li>
                        <li>{t('campagne_notoriete.sections.1.liste.3')}</li>
                        <li>{t('campagne_notoriete.sections.1.liste.4')}</li>
                      </ul>
                      <p>{t('campagne_notoriete.sections.1.conclusion')}</p>
                    </Col>
                  </Row>
                </Container>
              </Section>
              <Section className={css(Classes.section)}>
                <Container>
                  <Row>
                    <Col>
                      <SliderBasic
                        responsive={responsive}
                        slidesToShow={4}
                        autoplaySpeed={2000}
                        slidesToScroll={1}
                        autoplay
                      >
                        {ImgButtons}
                      </SliderBasic>
                    </Col>
                  </Row>
                </Container>
              </Section>
            </SecondaryLayout>
          )
        }}
      />
    )
  }
}

export default withNamespaces('home')(CampagneDeNotoriete)
